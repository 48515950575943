<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase" style="width: 100%;">
            {{ $t('customerService.inactiveCustomers.survey.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ displayName(customer) }} <a :href="'tel:' + phoneInt(customer)" target="_blank" class="white--text">{{ displayPhone(customer) }}</a>
          </div>
        </template>

        <v-container>
          <v-card class="main-card">
            <v-stepper v-model="step" vertical dark>
              <v-stepper-step :editable="step >= 1 && step < 4" :complete="step > 1" step="1">
                {{ $t('customerService.inactiveCustomers.survey.start.title') }}
                <small>{{ $t('customerService.inactiveCustomers.survey.start.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="1" class="white--text">
                {{ $t('customerService.inactiveCustomers.survey.start.intro') }}
                <v-radio-group v-model="start">
                  <v-radio :label="$t('customerService.inactiveCustomers.survey.start.notInterested')" value="notInterested" />
                  <v-radio :label="$t('customerService.inactiveCustomers.survey.start.notNow')" value="notNow" />
                  <v-radio :label="$t('customerService.inactiveCustomers.survey.start.notACustomer')" value="notACustomer" />
                  <v-radio :label="$t('customerService.inactiveCustomers.survey.start.yes')" value="yes" />
                </v-radio-group>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="validateStep1">
                  {{ $t('customerService.inactiveCustomers.survey.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :editable="step >= 2 && step < 4" :complete="step > 2" step="2">
                {{ $t('customerService.inactiveCustomers.survey.negative.title') }}
                <small>{{ $t('customerService.inactiveCustomers.survey.negative.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="2" class="white--text">
                <div class="mb-2 white--text">
                  <v-checkbox v-model="negativeDelay" :label="$t('customerService.inactiveCustomers.survey.negative.delay.label')" :value="$t('customerService.inactiveCustomers.survey.negative.delay.value')" hide-details />
                  <span v-if="negativeDelay">{{ $t('customerService.inactiveCustomers.survey.negative.delay.description') }}</span>
                  <v-checkbox v-model="negativePrice" :label="$t('customerService.inactiveCustomers.survey.negative.price.label')" :value="$t('customerService.inactiveCustomers.survey.negative.price.value')" hide-details />
                  <span v-if="negativePrice">{{ $t('customerService.inactiveCustomers.survey.negative.price.description') }}</span>
                  <v-checkbox v-model="negativeExperience" :label="$t('customerService.inactiveCustomers.survey.negative.experience.label')" :value="$t('customerService.inactiveCustomers.survey.negative.experience.value')" hide-details />
                  <span v-if="negativeExperience">{{ $t('customerService.inactiveCustomers.survey.negative.experience.description') }}</span>
                  <v-checkbox v-model="negativeQuality" :label="$t('customerService.inactiveCustomers.survey.negative.quality.label')" :value="$t('customerService.inactiveCustomers.survey.negative.quality.value')" hide-details />
                  <span v-if="negativeQuality">{{ $t('customerService.inactiveCustomers.survey.negative.quality.description') }}</span>
                  <v-checkbox v-model="showNegativeOther" :label="$t('customerService.inactiveCustomers.survey.negative.other.label')" hide-details />
                  <v-text-field v-if="showNegativeOther" v-model="negativeOther" :label="$t('customerService.inactiveCustomers.survey.negative.other.label')" hide-details />
                </div>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="validateStep2">
                  {{ $t('customerService.inactiveCustomers.survey.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :editable="step >= 2 && step < 4" :complete="step > 3" step="3">
                {{ $t('customerService.inactiveCustomers.survey.positive.title') }}
                <small>{{ $t('customerService.inactiveCustomers.survey.positive.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="3" class="white--text">
                <div class="mb-2">
                  <v-checkbox v-model="positiveDelay" :label="$t('customerService.inactiveCustomers.survey.positive.delay.label')" :value="$t('customerService.inactiveCustomers.survey.positive.delay.value')" hide-details />
                  <v-checkbox v-model="positivePrice" :label="$t('customerService.inactiveCustomers.survey.positive.price.label')" :value="$t('customerService.inactiveCustomers.survey.positive.price.value')" hide-details />
                  <v-checkbox v-model="positiveExperience" :label="$t('customerService.inactiveCustomers.survey.positive.experience.label')" :value="$t('customerService.inactiveCustomers.survey.positive.experience.value')" hide-details />
                  <v-checkbox v-model="positiveQuality" :label="$t('customerService.inactiveCustomers.survey.positive.quality.label')" :value="$t('customerService.inactiveCustomers.survey.positive.quality.value')" hide-details />
                  <v-checkbox v-model="showPositiveOther" :label="$t('customerService.inactiveCustomers.survey.positive.other.label')" hide-details />
                  <v-text-field v-if="showPositiveOther" v-model="positiveOther" :label="$t('customerService.inactiveCustomers.survey.positive.other.label')" hide-details />
                </div>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="validateStep3">
                  {{ $t('customerService.inactiveCustomers.survey.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="step > 4" step="4">
                {{ $t('customerService.inactiveCustomers.survey.gift.title') }}
                <small>{{ $t('customerService.inactiveCustomers.survey.gift.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="4" class="white--text">
                {{ $t('customerService.inactiveCustomers.survey.gift.thanks', discount) }}
                <div v-if="customer && customer.phone">
                  {{ $t('customerService.inactiveCustomers.survey.gift.phone') }}
                </div>
                <div v-if="customer && customer.email">
                  {{ $t('customerService.inactiveCustomers.survey.gift.email') }}
                </div>
                <br>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="validate()">
                  {{ $t('customerService.inactiveCustomers.survey.button.validate') }}
                </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-container>
        <v-card-actions />
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';

  export default {
    name: 'InactiveCustomerSurveyForm',
    props: {
      customer: {
        type: Object,
        required: false
      }
    },
    data () {
      return {
        show: false,
        showNegativeOther: false,
        showPositiveOther: false,
        loading: false,
        valid: true,
        step: 1,
        start: '',
        negativeDelay: undefined,
        negativePrice: undefined,
        negativeExperience: undefined,
        negativeQuality: undefined,
        negativeOther: undefined,
        positiveDelay: undefined,
        positivePrice: undefined,
        positiveExperience: undefined,
        positiveQuality: undefined,
        positiveOther: undefined,
        discount: {}
      };
    },
    watch: {
      customer: function () {
        this.show = this.customer !== undefined;
      },
      show: function () {
        this.step = 1;
        this.start = '';
        this.showNegativeOther = false;
        this.showPositiveOther = false;
        this.negativeDelay = undefined;
        this.negativePrice = undefined;
        this.negativeExperience = undefined;
        this.negativeQuality = undefined;
        this.negativeOther = undefined;
        this.positiveDelay = undefined;
        this.positivePrice = undefined;
        this.positiveExperience = undefined;
        this.positiveQuality = undefined;
        this.positiveOther = undefined;
        this.discount = {};
      }
    },
    methods: {
      phoneInt (customer) {
        if (customer === undefined) {
          return '';
        }

        return phoneInt(customer.phone);
      },
      displayPhone (customer) {
        if (customer === undefined) {
          return '';
        }

        return displayPhone(customer.phone);
      },
      displayName (customer) {
        if (customer === undefined) {
          return '';
        }

        return customer.firstName + ' ' + customer.lastName;
      },
      async validateStep1 () {
        switch (this.start) {
          case 'notInterested':
            await this.callInactiveCustomer(this.start, 'suspend');
            this.validate();
            break;
          case 'notNow':
            await this.callInactiveCustomer(this.start, 'retry');
            this.validate();
            break;
          case 'notACustomer':
            await this.callInactiveCustomer(this.start, 'suspend');
            this.validate();
            break;
          case 'yes':
          default:
            this.step = 2;
        }
      },
      validateStep2 () {
        this.step = 3;
      },
      async validateStep3 () {
        await this.callInactiveCustomer({
          positive: [
            this.positiveDelay,
            this.positivePrice,
            this.positiveExperience,
            this.positiveQuality,
            this.positiveOther
          ].filter((info) => info !== undefined && info !== null),
          negative: [
            this.negativeDelay,
            this.negativePrice,
            this.negativeExperience,
            this.negativeQuality,
            this.negativeOther
          ].filter((info) => info !== undefined && info !== null)
        }, 'done');
        this.step = 4;
      },
      validate () {
        this.$emit('success', false);
        this.show = false;
      },
      async callInactiveCustomer (note, status) {
        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'customer/inactive/call', {
            phone: this.customer.phone,
            email: this.customer.email,
            note: typeof note === 'string' ? note : JSON.stringify(note),
            status: status
          });
          if (response.data !== undefined && response.data.discount !== undefined) {
            this.discount = response.data.discount;
            this.discount.valueType = this.discount.valueType === 'percentage' ? '%' : '€';
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
      }
    }
  };
</script>

<template>
  <v-container id="inactive-customers" fluid tag="section">
    <alert-notification :message="alert" />
    <h1 class="pa-4">
      {{ $t('customerService.inactiveCustomers.subtitle') }}
    </h1>
    <v-banner color="quinary" icon="mdi-information-box" dark outlined rounded class="mb-6 ma-auto" style="max-width: 800px;">
      <div>
        <h4>Clients ayant déjà commandé chez Burger Breton mais qui n'ont plus commandé depuis plus de 3 mois.</h4>
        <h5>Nous souhaitons recueillir leur avis sur Burger Breton et leur offrir un cadeau pour leur prochaine commande !</h5>
      </div>
    </v-banner>
    <v-row v-if="isOpen || $can('read', 'Report.Meta')">
      <v-col cols="12" class="text-center">
        <div v-if="countTotalCustomers > 0 || loading">
          <v-btn :disabled="loading" :loading="loading" color="primary" @click.stop="selectCustomer()">
            {{ $t('customerService.inactiveCustomers.button.start') }} ({{ countTotalCustomers }})
          </v-btn>
          <inactive-customer-survey-form :customer="customer" @success="success" @fail="fail" />
        </div>
        <div v-else>
          {{ $t('customerService.inactiveCustomers.noInactiveCustomer') }}
        </div>
      </v-col>
      <v-col cols="12">
        <customer-service-tasks action="call_inactive_customer" :editable="true" :recall="true" :refresh="refresh" @success="success" @fail="fail" />
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <div class="pa-2">
        <div class="text-h3">
          {{ $t('customerService.inactiveCustomers.isClosed') }}
        </div>
      </div>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <customer-service-tasks action="call_inactive_customer" :editable="true" :refresh="refresh" @success="success" @fail="fail" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import InactiveCustomerSurveyForm from '@/views/forms/meta/customerService/InactiveCustomerSurveyForm';
  import CustomerServiceTasks from '@/views/components/business/customerService/CustomerServiceTasks';

  export default {
    name: 'MetaInactiveCustomersV2',
    components: { AlertNotification, CustomerServiceTasks, InactiveCustomerSurveyForm },
    data () {
      return {
        alert: '',
        loading: false,
        customer: undefined,
        customers: [],
        countTotalCustomers: 0,
        refresh: Date.now()
      };
    },
    computed: {
      isOpen () {
        const date = DateTime.utc().setZone('Europe/Paris');
        if ([2, 3, 4, 5].includes(date.weekday)) {
          return (date.hour >= 16 && date.hour < 20) || (date.hour === 20 && date.minute <= 30);
        }

        if ([6].includes(date.weekday)) {
          return (date.hour >= 11 && date.hour < 20) || (date.hour === 20 && date.minute <= 30);
        }

        return false;
      }
    },
    mounted () {
      this.refreshData();
    },
    methods: {
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      formatPhone (phone) {
        return '<a href="tel:' + phoneInt(phone) + '" target="_blank">' + displayPhone(phone) + '</a>';
      },
      async selectCustomer () {
        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'customer/inactive/select');
          if (response.data !== undefined) {
            this.customer = response.data.customer;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
        this.refresh = Date.now();
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'customers/inactive');
          if (response.data !== undefined) {
            this.customers = response.data.customers;
            this.countTotalCustomers = response.data.count;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
        this.refresh = Date.now();
      },
      async success (openSurvey) {
        this.customer = undefined;
        this.alert = '';
        this.loading = false;
        await this.refreshData();
        if (openSurvey) {
          await this.selectCustomer();
        }
      },
      async fail (error, message) {
        this.customer = undefined;
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
